import React, { Component } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import TrainingPreview from '../molecules/TrainingPreview';
import TrainingListHeader from '../molecules/TrainingListHeader';

const Holder = styled.div`
  margin: 10rem 0;
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  h2 {
    margin-bottom: 4rem;
  }
  .link-to-all {
    margin: 4rem 0;
  }
`;

class TrainingCalendarPreview extends Component {
  render() {
    return (
      <Holder>
        <h2>Upcoming Training Courses</h2>
        {/*<small className="link-to-all"><Link className="button" to="/training-calendar">View all
          training courses &rarr;</Link></small>*/}
        <StaticQuery
          query={graphql`
              query TrainingCalendarQuery {
                allContentfulTrainingDate(sort: {fields: date, order: ASC}) {
                  nodes {
                    id
                    date(formatString: "DD.MM.YY")
                    location
                  }
                }
                allContentfulTraining {
                    nodes {
                        id
                        title
                        slug
                        duration
                        availableOnDemand
                        excerpt {
                            excerpt
                        }
                        vendor{
                            title
                            logo {
                                fluid(maxWidth: 500) {
                                    base64
                                    sizes
                                    src
                                    srcSet
                                    aspectRatio
                                }
                            }
                            slug
                        }
                        dates {
                            id
                        }
                    }
                }
              }
            `}
          render={data => (
            <>
              <TrainingListHeader/>
              {data.allContentfulTrainingDate.nodes.map( node => (
                <TrainingPreview key={node.id} date={node} allTraining={data.allContentfulTraining}/>
              ) )}
            </>
          )}
        />
      </Holder>
    )
  }
}

export default TrainingCalendarPreview;
