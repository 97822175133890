import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { PropTypes } from 'prop-types';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media(${props => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Tile = styled.div`
  a {
    text-decoration: none;
    &:hover {
      small { text-decoration: underline; }
    }
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

function EventList( { events } ) {
  return (
    <Holder>
      {events.map( event => {
        // Don't show the schema post
        if ( event.slug === 'schema-post-do-not-delete-or-edit' ) { return null; }

        return (
          <Tile key={event.id}>
            <Link to={`/event/${event.slug}`}>
              <h4>{event.title}</h4>
              <p>{event.excerpt && event.excerpt.excerpt}</p>
              <small>Read more &rarr;</small>
            </Link>
          </Tile>
        )
      } )}
    </Holder>
  )
}

EventList.propTypes = {
  events: PropTypes.array.isRequired,
};

export default EventList;
