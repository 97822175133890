import React, { Component } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import VendorLogo from '../atoms/VendorLogo';

const Holder = styled.div`
  margin: 10rem 0;
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;
const Vendors = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 4rem;
  @media(${props => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 6rem;
  }
  @media(${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media(${props => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(6, 1fr);
  }
  .logo-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

class TrainingVendorsList extends Component {
  render() {
    return (
      <Holder>
        <h2>Vendors offering training</h2>
        <StaticQuery
          query={graphql`
              query TrainingVendorQuery {
                allContentfulVendor {
                  nodes {
                    title
                    slug
                    training {
                      title
                    }
                    logo {
                      fluid(maxWidth: 1000) {
                        src
                        srcSet
                        sizes
                        aspectRatio
                      }
                    }
                  }
                }
              }
            `}
          render={data => (
            <Vendors>
              {data.allContentfulVendor.nodes.map( vendor => {
                if ( vendor.training ) {
                  return (
                    <div className="logo-holder">
                      <VendorLogo
                        vendor={vendor}
                        width="100%"
                        base="vendor-training"
                        center
                        showTitle={false}/>
                    </div>
                  )
                } else {
                  return null;
                }
              } )}
            </Vendors>
          )}
        />
      </Holder>
    )
  }
}

export default TrainingVendorsList;
